import { useContext, useState, useEffect, useCallback } from "react"
import { MapContext } from "../MapProvider"
import ol_vector_Source from "ol/source/Vector"
import ol_format_GeoJSON from "ol/format/GeoJSON"
import ol_layer_VectorImage from 'ol/layer/VectorImage';
import {Fill, Stroke, Style} from 'ol/style';
import ol_interaction_Select from 'ol/interaction/Select';

const selectStyle = new Style({
    stroke: new Stroke({
        color: '#00F',
        width: 1,
    }),
    zIndex: 1000,
    fill: new Fill({
        color: '#0000FF'
    }),
})   

const generateCategorizedStyle = (type, value) => {
    
    const getColor = () => {
        if(value === undefined || value === null) return "#fff"

        const item = type.find(x => value === x.value)
        return item ? item.color : "#cdcdcd";
    }

    return new Style({
        fill: new Fill({
          color: getColor()
        }),
        stroke: new Stroke({
          color: "#efefef",
          width: 0.3
        }),
      });
}

const generateGradientStyle = (type, value, filter) => {
    const getColor = () => {
        if(value === undefined || value === null) return "#fff"
        if(value >= filter[1] || value <= filter[0]) return "#fff"

        const item = type.find(x => value <= x.value)
        return item ? item.color : "#fff";
    }

    return new Style({
        fill: new Fill({
          color: getColor()
        }),
        stroke: new Stroke({
          color: "#efefef",
          width: 0.3
        }),
      });
}

const GeoJSONLayer = ({
    url,
    visible,
    style,
    id,
    rules,
    callIdentification,
    joinedDatasets,
    allowIdentification
}) => {
    
    
    const activeRule = rules.find(x => x.active);

    const layerStyle = new Style({
        stroke: new Stroke({
            color: style.stroke.color,
            width: style.stroke.width
        }),
    })   

    const map = useContext(MapContext)

    const getGradientStyle = useCallback((feature, rule) => {
        if(rule.type === "categorized")
            return generateCategorizedStyle(rule.style, feature.get(rule.attribute))
        else {
            const value = typeof rule.attribute === "function" ? rule.attribute(feature) : feature.get(rule.attribute);
            return generateGradientStyle(rule.style, value, rule.rangeFilter)
        }
    }, [])

    const l = new ol_layer_VectorImage({
        imageRatio: 1,
        visible,
        source: new ol_vector_Source({
            url,
            format: new ol_format_GeoJSON()
        }),
        style: (feature) => {
            if(activeRule){
                return getGradientStyle(feature, activeRule)
            }
            return layerStyle
        }
    })
    
     
    const [olLayer] = useState(l)

    useEffect(() => {
        const selectClick = new ol_interaction_Select({layers: [olLayer], style: null});

        if(allowIdentification){
            map.addInteraction(selectClick);
        }

        selectClick.on('select', function(e) {
            if(!e.selected.length) return
            const selected = e.selected[0]
            callIdentification(id, selected)
        })

        return () => {
            map.removeInteraction(selectClick);
        }

    }, [map, olLayer, allowIdentification, callIdentification, id])

  


    useEffect(() => {
       const features = olLayer.getSource().getFeatures();
        features.forEach(feature => {
            if(joinedDatasets){
                joinedDatasets.forEach(dataset => {
                    const data = dataset.dataset && dataset.dataset.data && dataset.dataset.data.find(x => feature.get(dataset.joinColumn) === x[dataset.joinedColumn]);
                    feature.setProperties({...feature.getProperties(), ...data})
                })
            }
            const s = getGradientStyle(feature, activeRule);
            feature.setStyle(s);
        })
    }, [joinedDatasets, activeRule, getGradientStyle, olLayer])

    useEffect(() => {
        olLayer && olLayer.setVisible(visible)
    }, [olLayer, visible])

    useEffect(() => {
        map.addLayer(olLayer)
        return () => {
            map.removeLayer(olLayer)
        }
    }, [map, olLayer])
    
    return null
}

GeoJSONLayer.defaultProps = {
    rules: [],
    style: {
        
    }
}

export default GeoJSONLayer