import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles, CardHeader, IconButton} from '@material-ui/core'
import Graph from '../components/Graph'
import CloseIcon from '@material-ui/icons/Close'
import dateformat from "dateformat"
const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 500,
        paddingRight: 30,
        width: "100%",
        overflow: "hidden"
        // position: "relative"
    },
    closeButton: {
        top: 8,
        right: 8,
        left: "auto",
        position: "absolute"
    }
}))

function parse(str) {
    if(!/^(\d){8}$/.test(str)) return "invalid date";
    var y = str.substr(0,4),
        m = str.substr(4,2) - 1,
        d = str.substr(6,2);
    return new Date(y,m,d);
}

const InfoDialog = () => {

    const results = useSelector(state => state.identification.results)
    const dispatch = useDispatch()
    const classes = useStyles()

    const attributes = results && results.length && results[0].feature.getProperties()
    const closeDialog = () => {
        dispatch({type: "SET_IDENTIFICATION", results: []})
    }
    debugger
    const graph = Object.entries(attributes).filter(([attr, value]) => {
        if(isNaN(attr)) return false;
            
        return true
    })

    const graphData = graph.map(x => ({
        title: dateformat(parse(x[0]), "d.m.yyyy"),
        value: x[1]
    }));
    // function compare( a, b ) {
    //     if ( a.value < b.value ){
    //       return 1;
    //     }
    //     if ( a.value > b.value ){
    //       return -1;
    //     }
    //     return 0;
    //   }

    // attributes.sort(compare)

    return (
        <Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" open={attributes ? true : false} classes={{paper: classes.root}}>   
            <IconButton className={classes.closeButton} onClick={closeDialog}>
                <CloseIcon />
            </IconButton>         
         
                         <CardHeader title={attributes.nm}/>
                        <Graph data={graphData}/>
                
        </Dialog>
    )

}

export default InfoDialog