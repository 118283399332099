import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import MapProvider from './ol/MapProvider'
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './store/root'

const store = createStore(rootReducer)

ReactDOM.render(
    <Provider store={store}>
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <MapProvider> 
            <App />
        </MapProvider>
    </ThemeProvider>
    </Provider>, 
document.getElementById('root'));

serviceWorker.unregister();
