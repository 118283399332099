import general from './general';
import map from './map';
import dataset from './dataset';
import timeline from './timeline';
import identification from './identification';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    general,
    dataset,
    timeline,
    identification,
    map
})

export default rootReducer