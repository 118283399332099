import config from "../config"

const initState = config.map;

const map = (state = initState, action) => {
    switch (action.type) {
        case 'SET_BASELAYER_VISIBILITY':
            return {
                ...state,
                baseLayers: state.baseLayers.map(bl => ({
                    ...bl,
                    visible: bl.id === action.id ? true : false
                }))
            }
        case 'SET_LAYER_VISIBILITY':
            return {
                ...state,
                layers: state.layers.map(l => ({
                    ...l,
                    visible: l.id === action.id ? !l.visible : l.visible
                }))
            }
        case 'ACTIVATE_LAYER_RULE':
            return {
                ...state,
                layers: state.layers.map(l => ({
                    ...l,
                    rules: action.layerId === l.id ? l.rules.map(rule => ({
                        ...rule,
                        active: rule.id === action.ruleId ? true : false
                    })) : l.rules
                }))
            }
        case 'SET_RANGE_FILTER':
            return {
                ...state,
                layers: state.layers.map(l => ({
                    ...l,
                    rules: action.layerId === l.id ? l.rules.map(rule => ({
                        ...rule,
                        rangeFilter: rule.id === action.ruleId ? action.range : rule.rangeFilter
                    })) : l.rules
                }))
            }
        case 'SET_LAYERS':
            return {
                ...state,
                layers: action.layers
            }
        case 'ADD_LAYER':
            return {
                ...state,
                layers: [
                    ...state.layers, 
                    action.layer
                ]
            }
        case 'REMOVE_LAYER':
            return {
                ...state,
                layers: state.layers.filter(l => l.id !== action.id)
            }
        default:
            return state
    }
}

export default map;