
const initState = {
    datasets: []
}

const datasets = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_DATASET':
            return {
                ...state,
                datasets: [...state.datasets, { name: action.name, data: action.data } ]
            }
        default:
            return state
    }
}

export default datasets;