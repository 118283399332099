//https://api.apify.com/v2/datasets/oUWi8ci7F2R9V5ZFy/items?format=json&clean=1
//https://mojeezdravie.nczisk.sk/api/v1/svk-covid-driveins
const config = {
    general: {
        showMenu: true,
        showMobileMenu: false
    },
    map: {
        baseLayers: [
            {
                id: 1,
                title: "OpenStreetMap",
                type: "osm",
                baselayer: true,
                visible: false,
                image: "icon_1.png"
            },
            // {
            //     id: 2,
            //     title: "Ortofotomapa SR",
            //     type: "tilewms",
            //     baselayer: true,
            //     url: "https://zbgisws.skgeodesy.sk/zbgis_ortofoto_wms/service.svc/get?",
            //     visible: true,
            //     image: "icon_2.png",
            //     params: {
            //         LAYERS: "0,1",
            //         FORMAT: "image/jpeg"
            //     }
            // },
            // {
            //     id: 3,
            //     title: "ZBGIS",
            //     type: "tilewms",
            //     baselayer: true,
            //     image: "icon_3.png",
            //     url: "https://zbgisws.skgeodesy.sk/zbgis_wms_featureinfo/service.svc/get",
            //     visible: true,
            //     params: {
            //         LAYERS: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,95,97,98,99,100,102,103,104,105,107,109,110,111,112,114,115,116,117,118,119,121,122,123,124,125,126,128,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,148,150,152,153,154,155,156,157,159,160,161,162,163,164,166",
            //         FORMAT: "image/png"
            //     }
            // },
        ],
        layers: [
           
            {
                id: 5,
                title: "Celkový počet pozitívne testovaných na 10 000 obyv.",
                type: "geojson",
                url: "/data/okres_covid.json",
                visible: true,
                allowIdentification: true,
                filter: {
                   
                },
                joins: [{
                    datasetName: "covid_data",
                    joinColumn: "nm",
                    joinedColumn: "name"
                }],
                rules: [{
                    id: 11,
                    active: true,
                    title: "18.10.2020",
                    type: "scale",
                    attribute: (feature) => {
                        return feature.get("20201018") * 10000 / feature.get("pop")
                    },
                    rangeFilter: [0, 301],
                    rangeFilterScale: [0, 301],
                    unit: "",
                    style: [{
                        value: 10,
                        color: "#ffd7d4"
                    },{
                        value: 15,   
                        color: "#faa49d"
                    },{
                        value: 20,
                        color: "#fa7266"
                    },{
                        value: 30,
                        color: "#d93325"
                    },{
                        value: 301,
                        color: "#94150a"
                    }]
                },{
                    id: 10,
                    active: true,
                    title: "11.10.2020",
                    type: "scale",
                    attribute: (feature) => {
                        return feature.get("20201011") * 10000 / feature.get("pop")
                    },
                    rangeFilter: [0, 191],
                    rangeFilterScale: [0, 191],
                    unit: "",
                    style: [{
                        value: 10,
                        color: "#ffd7d4"
                    },{
                        value: 15,   
                        color: "#faa49d"
                    },{
                        value: 20,
                        color: "#fa7266"
                    },{
                        value: 30,
                        color: "#d93325"
                    },{
                        value: 191,
                        color: "#94150a"
                    }]
                },{
                    id: 9,
                    active: true,
                    title: "4.10.2020",
                    type: "scale",
                    attribute: (feature) => {
                        return feature.get("20201004") * 10000 / feature.get("pop")
                    },
                    rangeFilter: [0, 129],
                    rangeFilterScale: [0, 129],
                    unit: "",
                    style: [{
                        value: 10,
                        color: "#ffd7d4"
                    },{
                        value: 15,   
                        color: "#faa49d"
                    },{
                        value: 20,
                        color: "#fa7266"
                    },{
                        value: 30,
                        color: "#d93325"
                    },{
                        value: 129,
                        color: "#94150a"
                    }]
                }, {
                    id: 8,
                    active: false,
                    title: "27.9.2020",
                    type: "scale",
                    attribute: (feature) => {
                        return feature.get("20200927") * 10000 / feature.get("pop")
                    },
                    rangeFilter: [0, 82],
                    rangeFilterScale: [0, 82],
                    unit: "",
                    style: [{
                        value: 10,
                        color: "#ffd7d4"
                    },{
                        value: 15,   
                        color: "#faa49d"
                    },{
                        value: 20,
                        color: "#fa7266"
                    },{
                        value: 30,
                        color: "#d93325"
                    },{
                        value: 82,
                        color: "#94150a"
                    }]
                },{
                    id: 7,
                    active: false,
                    title: "20.9.2020",
                    type: "scale",
                    attribute: (feature) => {
                        return feature.get("20200920") * 10000 / feature.get("pop")
                    },
                    rangeFilter: [0, 49],
                    rangeFilterScale: [0, 49],
                    unit: "",
                    style: [{
                        value: 10,
                        color: "#ffd7d4"
                    },{
                        value: 15,   
                        color: "#faa49d"
                    },{
                        value: 20,
                        color: "#fa7266"
                    },{
                        value: 30,
                        color: "#d93325"
                    },{
                        value: 49,
                        color: "#94150a"
                    }]
                },{
                    id: 6,
                    active: false,
                    title: "13.9.2020",
                    type: "scale",
                    attribute: (feature) => {
                        return feature.get("20200913") * 10000 / feature.get("pop")
                    },
                    rangeFilter: [0, 30],
                    rangeFilterScale: [0, 30],
                    unit: "",
                    style: [{
                        value: 10,
                        color: "#ffd7d4"
                    },{
                        value: 15,   
                        color: "#faa49d"
                    },{
                        value: 20,
                        color: "#fa7266"
                    },{
                        value: 30,
                        color: "#d93325"
                    }]
                },{
                    id: 5,
                    active: false,
                    title: "6.9.2020",
                    type: "scale",
                    attribute: (feature) => {
                        return feature.get("20200906") * 10000 / feature.get("pop")
                    },
                    rangeFilter: [0, 24],
                    rangeFilterScale: [0, 24],
                    unit: "",
                    style: [{
                        value: 10,
                        color: "#ffd7d4"
                    },{
                        value: 15,   
                        color: "#faa49d"
                    },{
                        value: 20,
                        color: "#fa7266"
                    },{
                        value: 24,
                        color: "#d93325"
                    }]
                },{
                    id: 4,
                    active: false,
                    title: "30.8.2020",
                    type: "scale",
                    attribute: (feature) => {
                        return feature.get("20200830") * 10000 / feature.get("pop")
                    },
                    rangeFilter: [0, 22],
                    rangeFilterScale: [0, 22],
                    unit: "",
                    style: [{
                        value: 10,
                        color: "#ffd7d4"
                    },{
                        value: 15,   
                        color: "#faa49d"
                    },{
                        value: 20,
                        color: "#fa7266"
                    },{
                        value: 22,
                        color: "#d93325"
                    }]
                }
              ],
              style: {
                        stroke: {
                            color: '#efefef',
                            width: 1.5
                        }
                        
                    }
            },
            // {
            //     id: 5,
            //     title: "Okresy",
            //     type: "geojson",
            //     url: "/data/okres_covid.json",
            //     visible: true,
            //     filter: {
                   
            //     },
            //     joins: [{
            //         datasetName: "covid",
            //         joinColumn: "nm",
            //         joinedColumn: "title"
            //     }],
            //     rules: [{
            //         id: 1,
            //         active: true,
            //         title: "Počet pozitívne testovaných na 10 000 obyv.",
            //         type: "scale",
            //         attribute: (feature) => {
            //             return feature.get("infected") * 10000 / feature.get("pop")
            //         },
            //         rangeFilter: [0, 100],
            //         rangeFilterScale: [0, 100],
            //         unit: "",
            //         style: [{
            //             value: 3,
            //             color: "#ffd7d4"
            //         },{
            //             value: 5,   
            //             color: "#faa49d"
            //         },{
            //             value: 10,
            //             color: "#fa7266"
            //         },{
            //             value: 20,
            //             color: "#d93325"
            //         },{
            //             value: 100,
            //             color: "#94150a"
            //         }]
            //     },
            //     {
            //         attribute: "infected",
            //         id: 2,
            //         active: true,
            //         title: "Počet pozitívnych testov",
            //         type: "scale",
            //         rangeFilter: [0, 3000],
            //         rangeFilterScale: [0, 3000],
            //         unit: "",
            //         style: [{
            //             value: 50,
            //             color: "#ffd7d4"
            //         },{
            //             value: 100,
            //             color: "#faa49d"
            //         },{
            //             value: 150,
            //             color: "#fa7266"
            //         },{
            //             value: 250,
            //             color: "#d93325"
            //         },{
            //             value: 3000,
            //             color: "#94150a"
            //         }]
            //     }],
            //     style: {
            //         stroke: {
            //             color: '#efefef',
            //             width: 1.5
            //         }
                    
            //     }
            // },
            {
                id: 2,
                title: "Kraje",
                type: "geojson",
                url: "/data/kraj.json",
                visible: true,
                rules: [],
                displayInLayerSwitcher: false,
                style: {
                    stroke: {
                        color: '#efefef',
                        width: 2.5
                    }
                }
            },
            {
                id: 3,
                title: "Okresy",
                type: "geojson",
                url: "/data/okres.json",
                visible: true,
                rules: [],
                displayInLayerSwitcher: false,
                style: {
                    stroke: {
                        color: '#efefef',
                        width: 1.5
                    }
                }
            }
            // {
            //     id: 1,
            //     title: "KN - Určený operát",
            //     type: "tilewms",
            //     url: "https://kataster.skgeodesy.sk/eskn/services/NR/uo_wms_norm/MapServer/WmsServer?",
            //     visible: false,
            //     params: {
            //         LAYERS: "0,1,2,3,4,5",
            //         FORMAT: "image/png"
            //     }
            // },
            // {
            //     id: 2,
            //     title: "KN - Katastrálna mapa",
            //     type: "tilewms",
            //     url: "https://kataster.skgeodesy.sk/eskn/services/NR/kn_wms_norm/MapServer/WmsServer",
            //     visible: false,
            //     params: {
            //         LAYERS: "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15",
            //         FORMAT: "image/png"
            //     }
            // }
        ],
        view: {
            center: [19.2, 48.7],
            zoom: 8,
            minZoom: 5,
            maxZoom: 10
        }
    },
    timeline: {
        layers: [5],
        min: 4,
        max: 11,
        value: 11
    },
    identification: {
        results: [
            
        ]
    }
}

export default config;