import { withStyles } from "@material-ui/core/styles";
import React from 'react'
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography'
import { useSelector, useDispatch } from "react-redux";

const styles = (theme) => ({
    root: {
        position: "absolute",
        top: 10,
        right: 10,
        maxWidth: 450,
        width: "80%",
        margin: "auto",
        background: "#fff",
        borderRadius: 4,
        paddingLeft: 24,
        paddingTop: 8,
        paddingRight: 24,
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        // [theme.breakpoints.down("md")]: {
        //     top: "auto",
        //     bottom: 80,
        // }
    },
    filterText: {
        fontWeight: 600
    },
    sliderLabel: {
        "& > span  > span": {
            color: theme.palette.common.white
        }
    },
})

function valuetext(value) {
    return `${value}`;
}

const GlobalSlider = ({
    classes
}) => {

    const { map: {layers} } = useSelector(state => ({map: state.map}))
    const dispatch = useDispatch()

    let rangeFilter = [0, 100];
    
    const rangeLayers = layers.filter(layer => layer.visible && layer.type === "geojson" && layer.rules && layer.rules.find(rule => rule.type === "scale"))
    const setRangeFilter = (layerId, ruleId, range) => {
        dispatch({type: "SET_RANGE_FILTER", layerId, ruleId, range})
    }

    return (
        <div className={classes.root}>
            {rangeLayers.map((l) => {
                const activeLayerRule = l.rules.find(rule => rule.active) || {};
                return (
                    <div key={activeLayerRule.id}>
                        <Typography variant="caption" className={classes.filterText}>Celkový počet poz. testovaných na 10 000 obyv. k {activeLayerRule.title}</Typography>
                        <Slider
                            step={1}
                            min={activeLayerRule.rangeFilterScale[0]}
                            max={activeLayerRule.rangeFilterScale[1]}
                            classes={{root: classes.slider, valueLabel: classes.sliderLabel}}
                            defaultValue={activeLayerRule.rangeFilter || rangeFilter}
                            onChange={(e, newValue) => setRangeFilter(l.id, activeLayerRule.id, newValue)}
                            // onChangeCommitted={(e, newValue) => setRangeFilter(l.id, activeLayerRule.id, newValue)}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            valueLabelFormat={valuetext}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default withStyles(styles)(GlobalSlider)