import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Map from './ol/Map';
import Layout from './containers/Layout'
import LayersLoader from './ol/Layers';
// import SearchInput from './containers/SearchInput'
import Attribution from './components/Attribution';
import "./app.css";
import Slider from './containers/Slider';
import CovidDataLoader from './containers/CovidDataLoader';
import Timeline from './containers/Timeline';
import InfoDialog from './containers/InfoDialog';

const App = () => (
  <Router>
    <Layout>         
        <Map>
          <CovidDataLoader/>
          <LayersLoader/>
          <Timeline/>
          <InfoDialog/>
          {/* <SearchInput/> */}
        </Map>
        <Attribution>
            Vypracoval <a href="https://www.skymove.sk">SKYMOVE s.r.o.</a>, Zdroj údajov <a href="https://covid-19.nczisk.sk/sk">NCZI</a>
        </Attribution>
        <Slider/>
    </Layout>
  </Router>
)

export default App;
