import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const style = (theme) => ({
    root: {
        zIndex: 1,
        position: "absolute",
        bottom: 16,
        right: 16,
        padding: 8,
        color: "#353535",
        opacity: 0.5
    }
})

const Attribution = ({
    classes,
    children
}) => (
    <div className={classes.root}>
        {children}
    </div>
)

Attribution.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
}

export default withStyles(style)(Attribution)