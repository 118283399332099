import config from "../config";

const initState = config.general;

const general = (state = initState, action) => {
    switch (action.type) {
        case 'SHOW_MENU':
            return {
                ...state,
                showMenu: action.payload,
                showMobileMenu: action.payload
            }
        default:
            return state
    }
}

export default general;