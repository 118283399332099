import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {Slider, makeStyles, Typography} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        bottom: 10,
        left: 0,
        right: 0,
        margin: "auto",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        background: theme.palette.common.white,
        padding: 8,
        paddingLeft: 18,
        paddingRight: 18,
        borderRadius: 4,
        maxWidth: 350,
        width: "100%",
        zIndex: 9
    },
    title: {
        fontWeight: 700
    }
}))

function ValueLabelComponent(props) {
    const { children, open, value } = props;
  
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }


const Timeline = () => {

    const classes = useStyles();

    const {timeline, layers} = useSelector(state => ({ 
        timeline: state.timeline,
        layers: state.map.layers
    }));

    const dispatch = useDispatch()


    const timeLineLayer = timeline.layers && timeline.layers[0];

    if(!timeLineLayer || !layers) return null;

    const l = layers.find(x => x.id === timeLineLayer);

    const marks = l.rules.map(x => ({title: x.title, value: x.id}));
    const activeRule = l.rules.find(x => x.active)

    const activateLayerRule = (layerId, ruleId) => {
        dispatch({type: "ACTIVATE_LAYER_RULE", layerId, ruleId: parseInt(ruleId)})
    }

    return (
        <div className={classes.root}>
            <Typography variant="caption" className={classes.title}>Časová os: {activeRule.title}</Typography>
            <Slider
                defaultValue={(activeRule && activeRule.id) ? activeRule.id : timeline.value}
                value={(activeRule && activeRule.id) ? activeRule.id : timeline.value}
                min={timeline.min}
                max={timeline.max}
                ValueLabelComponent={ValueLabelComponent}
                valueLabelFormat={(value) => marks.find(x => x.value === value).title}
                step={null}
                onChange={(e, newValue) => activateLayerRule(l.id, newValue)}
                valueLabelDisplay="auto"
                marks={marks}
            />
        </div>
    );
}

export default Timeline;