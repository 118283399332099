import React from "react"
import {
    makeStyles,
    Typography
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    legend: {
        marginTop: 8,
        marginBottom: 8,
        marginLeft: 8,
        marginRight: 8,
        padding: 8
    },
    legendVertical: {
        marginLeft: 32
    },
    legendBox: {
        width: 45,
        height: 15
    },
    legendItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },   
    legendText: {
        width: 45,
        height: 20,
        paddingLeft: 4,
        fontSize: "0.9em",
        position: "relative",
        left: 14
    },
    zeroText: {
        paddingLeft: 4,
        fontSize: "0.9em",
    },
    legendHorizontalItem: {
        display: "flex",
        alignItems: "center",
        "& > div": {
            marginRight: 8,
            width: 25
        }
    }
}))

const LayerItemLegend = ({
    variant,
    legendItems,
    units
}) => {

    const classes = useStyles();

    return (
        variant === "vertical" ?
        <div className={classes.legendVertical}>
            {
                legendItems.map((g, idx) => (
                    <div key={idx} className={classes.legendHorizontalItem}>
                        <div className={classes.legendBox} style={{background: g.color}}></div>
                        <Typography variant="caption">{g.title}</Typography>
                    </div>
                ))
            }
        </div> :
        <div className={classes.legend}>
            <div  className={classes.legendItem}>
            <Typography className={classes.zeroText}>0{units}</Typography>
            {
                legendItems.map((g, idx) => (
                <Typography key={idx} className={classes.legendText}>{Math.round(g.value)}{units}</Typography>
                ))
            }
            </div>
            <div className={classes.legendItem}>
            {
                legendItems.map((g, idx) => (
                    <div key={idx} className={classes.legendBox} style={{background: g.color}}></div>
                ))
            }
            </div>
            {/* <Typography variant="caption" className={classes.filterText}>Filter</Typography>
            <Slider
                step={1}
                classes={{root: classes.slider, valueLabel: classes.sliderLabel}}
                defaultValue={rangeFilter}
                onChangeCommitted={(e, newValue) => setRangeFilter(newValue)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                valueLabelFormat={valuetext}
            /> */}
        </div>
    )
}

export default LayerItemLegend;