import config from "../config";

const initState = config.timeline;

const timeline = (state = initState, action) => {
    switch (action.type) {
        // case 'SHOW_MENU':
        //     return {
        //         ...state,
        //         showMenu: action.payload,
        //         showMobileMenu: action.payload
        //     }
        default:
            return state
    }
}

export default timeline;