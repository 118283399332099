import ol_layer_Tile from 'ol/layer/Tile';
import ol_source_OSM from 'ol/source/OSM';
import { useEffect, useState, useContext } from 'react';
import { MapContext } from '../MapProvider';

const OsmLayer = (props) => {

    const map = useContext(MapContext)
    const { visible, zindex } = props;

    const l = new ol_layer_Tile({
        visible,
        source: new ol_source_OSM({
            crossOrigin: 'anonymous'
        })
    })

    const [olLayer] = useState(l)

    useEffect(() => {
        olLayer.setProperties({ custom: props })
    }, [olLayer, props])

    useEffect(() => {
        olLayer && olLayer.setVisible(visible)
    }, [olLayer, visible])
    
    useEffect(() => {
        olLayer && olLayer.setZIndex(0.0001 * (zindex - 10000))
    }, [olLayer, zindex])

    useEffect(() => {
        map.addLayer(olLayer)
    }, [map, olLayer])

    return null

};

export default OsmLayer;
