import { useSelector, useDispatch } from "react-redux"
import List from '@material-ui/core/List';
import LayerIcon from '@material-ui/icons/LayersOutlined';
import React from 'react'
import { Typography, makeStyles } from "@material-ui/core";
import LayerItem from "../components/LayerSwitcher/LayerItem";

const useStyles = makeStyles((theme) => ({
    
    title: {
        paddingLeft: 16,
        color: theme.palette.grey[400]
    }   
}))

const LayerSwitcher = () => {

    const layers = useSelector(state => state.map.layers.filter(x => x.displayInLayerSwitcher !== false))
    const dispatch = useDispatch()
    const classes = useStyles()

    const setLayerVisibility = (id, visible) => {
        dispatch({type: "SET_LAYER_VISIBILITY", id, visible})
    }

    const activateLayerRule = (layerId, ruleId) => {
        dispatch({type: "ACTIVATE_LAYER_RULE", layerId, ruleId: parseInt(ruleId)})
    }

    return (
        <>  
        {layers.length ?
            <List className={classes.root}>
                <Typography gutterBottom className={classes.title}>Mapové vrstvy</Typography>
                {
                    layers.map((layer) => (      
                        <LayerItem
                            key={layer.id}                            
                            icon={<LayerIcon />}
                            id={layer.id}
                            title={layer.title}
                            activateLayerRule={activateLayerRule}
                            onChange={(id) => setLayerVisibility(id, !layer.visible)}
                            active={layer.visible}
                            {...layer}
                        />                                                            
                    ))
                }
            </List> : null
            }
        </>
    )
}

export default LayerSwitcher