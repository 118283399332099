import React from 'react';
import OSMLayer from './OSMLayer';
import { useDispatch, useSelector } from 'react-redux';
import TileWMS from './TileWMS';
import GeoJSONLayer from './GeoJSONLayer';
// import PoruchyGeoJsSON from './PoruchyGeoJSON';

const LayersLoader = () => {

  const layers = useSelector(state => state.map.layers)
  const baseLayers = useSelector(state => state.map.baseLayers)
  const datasets = useSelector(state => state.dataset.datasets)
  // const identificationResult = useSelector(state => state.identification.results)
  const dispatch = useDispatch()
  const callIdentification = (layerId, feature) => dispatch({type: "SET_IDENTIFICATION", results: [{layerId, feature}]})

  
  const base = baseLayers.map(layer => {
    if(layer.type.toLowerCase() === "osm")
      return <OSMLayer key={layer.id} {...layer} />
    if(layer.type.toLowerCase() === "tilewms")
      return <TileWMS key={layer.id} {...layer} />
    
    return null;
  })

  const commonLayers = layers.map(layer => {
    if(layer.type.toLowerCase() === "osm")
      return <OSMLayer key={layer.id} {...layer} />
    if(layer.type.toLowerCase() === "tilewms")
      return <TileWMS key={layer.id} {...layer} />
    if(layer.type.toLowerCase() === "geojson"){
      const joinedDatasets = layer.joins && layer.joins.map(join => ({
        ...join,
        dataset: datasets.find(d => d.name === join.datasetName),
      }))
      return (
        <GeoJSONLayer
            {...layer}
            joinedDatasets={joinedDatasets}
            callIdentification={callIdentification}
        />
      )
    }
    return null;
    
  })

  return (
    <>
      {base} {commonLayers}
    </>
  )
        
}

export default LayersLoader;
