import React, { useContext, useEffect } from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {MapContext} from './MapProvider';

const styles = (theme) => ({
    root: {
        width: "100%",
        height: "100%",
        position: "absolute",
    }
})

const MapPanel = ({
    classes,
    children
}) => {
    
    const map = useContext(MapContext)

    useEffect(() => { 
        map.setTarget('map')
        return () => {
            map.setTarget(null)
        }
    },[map])

    return (
        <>
            <div 
                className={classes.root} 
                id="map"
            />
            {children}
        </>
    )
    
}

MapPanel.propTypes = {
   classes: PropTypes.object,
   children: PropTypes.any
}

MapPanel.defaultProps = {
   
}

export default withStyles(styles)( MapPanel )