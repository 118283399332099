import { 
    Collapse, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    ListItemSecondaryAction, 
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Switch 
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles";
import React from 'react'
import {array} from "prop-types"
import LayerItemLegend from "./LayerItemLegend"
// import Slider from '@material-ui/core/Slider';

const styles = (theme) => ({
    slider: {
        width: "90%",
        marginLeft: 2,
        marginTop: 12,
    },
    filterText: {
        position: "relative",
        top: 16,
    },
    listItemText: {
        paddingRight: 24
    },
    radioGroupFormControl: {
        paddingLeft: 18
    },
    formControlLabel: {
        fontSize: "1em"
    },
    icon: {
        minWidth: 32       
    }
})



const LayerItem = ({
    title,
    onChange,
    id,
    active,
    classes,
    icon,
    activateLayerRule,
    rules
}) => {

   const activeRule = rules.find(x => x.active);
   const legendItems = activeRule ? activeRule.style : [];

    return (
        <>
            <ListItem  button onClick={() => onChange(id)} dense={true}>
                <ListItemIcon classes={{root: classes.icon}} >
                    {icon}
                </ListItemIcon>
                <ListItemText                              
                    primary={title}
                    className={classes.listItemText}
                />
                <ListItemSecondaryAction>
                    <Switch
                        edge="end"
                        onChange={() => onChange(id)}
                        checked={active}
                    />
                </ListItemSecondaryAction>
            </ListItem> 
            {activeRule &&
                <Collapse in={active}>
                    <FormControl className={classes.radioGroupFormControl} component="fieldset">
                        <RadioGroup aria-label="legend" name="legend" value={activeRule.id} onChange={(e) => activateLayerRule(id, e.target.value )}>
                        {
                            rules.map(rule => (
                                <>
                                    <FormControlLabel classes={{label: classes.formControlLabel}} value={rule.id} control={<Radio />} label={rule.title} />
                                    <Collapse in={activeRule.id === rule.id}>
                                        <LayerItemLegend
                                            units={activeRule.units}
                                            legendItems={legendItems}
                                            variant={activeRule && activeRule.legendType}
                                        />
                                    </Collapse>
                                </>
                            ))
                        }
                        </RadioGroup>
                    </FormControl>
                </Collapse>
            }   
        </>
    )
}

LayerItem.defaultProps = {
    rules: []
}

LayerItem.propTypes = {
    rules: array
}

export default withStyles(styles)(LayerItem)