import React, { useEffect, useState } from 'react';
import ol_Map from 'ol/Map';
import ol_View from 'ol/View';
import {transform} from 'ol/proj';
import { useSelector } from 'react-redux';

export const MapContext = React.createContext({});

const MapProvider = ({
    children
}) => {

  const [map, setMap] = useState()
  const view = useSelector(state => state.map.view)

  useEffect(() => {

    const mapCenter = transform(view.center, 'EPSG:4326', 'EPSG:3857');
    const map = new ol_Map({
      controls: [],
      view: new ol_View({
        center: mapCenter,
        zoom: view.zoom,
        projection: 'EPSG:3857',
        minZoom: view.minZoom,
        maxZoom: view.maxZoom
      })
    });
   
    setMap(map)

    return () => {
      map.setTarget(null)
    }
    
  }, [view])

 

  return map ?
    <MapContext.Provider value={map}>
      {children}
    </MapContext.Provider> : null

}

MapProvider.defaultProps = {
  
}

export default MapProvider