import ol_layer_Tile from 'ol/layer/Tile';
import ol_source_TileWMS from 'ol/source/TileWMS';
import { useEffect, useState, useContext } from 'react';
import { MapContext } from '../MapProvider';

const TileWMS = (props) => {

  const map = useContext(MapContext)
  const { visible, url, params} = props;
  
  const l = new ol_layer_Tile({
    source: new ol_source_TileWMS({
      url,
      crossOrigin: 'anonymous',
      params
    })
  })

  const [olLayer] = useState(l)

  useEffect(() => {
    olLayer.setProperties({ custom: props })
  }, [olLayer, props])

  useEffect(() => {
    olLayer && olLayer.setVisible(visible)
  }, [olLayer, visible])
  
  useEffect(() => {
    map.addLayer(olLayer)
  }, [olLayer, map])
  
  return null 

}

export default TileWMS;
