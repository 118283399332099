import { useEffect } from "react";
import { useDispatch } from "react-redux";

const CovidDataLoader = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        // const getData = async () => {
        //     const response = await fetch("https://mapa.covid.chat/map_data");
        //     const data = await response.json()
        //     const transformedData = data.districts.map(x => ({...x, ...x.amount}))
        //     const ba = transformedData.find(x => x.title === "Bratislava I");
        //     const ke = transformedData.find(x => x.title === "Košice I");
        //     const finalData = [...transformedData, {...ke, title: "Košice"}, {...ba, title: "Bratislava"}];
        //     dispatch({type: "ADD_DATASET", name: "covid", data: finalData})
        //     return data;
        // }
        const getCovidData = async () => {
            const response = await fetch("/data/covid_data.json");
            const data = await response.json()
            
            const transformedData = [];
            Object.entries(data).forEach(([date, values]) => {
                    
                    const d = values.reduce((prev, next) => {
                        return [
                            ...prev, { 
                                name: next.C[0],
                                [date]: next.C[1] || prev[prev.length - 1][date]
                            }
                        ]
                    }, [])
                    transformedData.push(d)

            });
           const merged = transformedData.reduce((prev, next, idx) => {
                return [...prev, ...next]
           }, [])
           const reduced = merged.reduce((prev, next, idx) => {
                let exists = prev.find(x => x.name === next.name);
                const obj = (x) => {
                    return x.name === next.name ? next : {}
                }
                if(exists)
                    return prev.map(x => ({...x, ...obj(x)}))
                return [...prev, next]
           }, [])
           dispatch({type: "ADD_DATASET", name: `covid_data`, data: reduced})
            


           
            // const ba = transformedData.find(x => x.title === "Bratislava I");
            // const ke = transformedData.find(x => x.title === "Košice I");
           
            return data;
        }
        // getData()
        setTimeout(getCovidData, 500)
    }, [ dispatch ])

    return null
}

export default CovidDataLoader