import config from "../config";

const initState = config.identification;

const identification = (state = initState, action) => {
    switch (action.type) {
        case 'SET_IDENTIFICATION':
            return {
                ...state,
                results: action.results
               
            }
        default:
            return state
    }
}

export default identification;